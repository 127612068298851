import { render, staticRenderFns } from "./justificativas.vue?vue&type=template&id=5947b980&scoped=true&"
import script from "./justificativas.vue?vue&type=script&lang=js&"
export * from "./justificativas.vue?vue&type=script&lang=js&"
import style0 from "./justificativas.vue?vue&type=style&index=0&id=5947b980&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5947b980",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDataTable,VIcon,VList,VListItemContent,VListItemTitle,VMenu,VSelect})
